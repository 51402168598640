/* LORDICON HOVER EFFECT */
.current-color {
    color: #fff;
}

.icon:hover .current-color {
    color: #03C988;
    transition: 0.3s;
}

/* TITLE */

[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.2s;
    visibility: visible;
}

[data-title]:after {
    content: attr(data-title);
    background-color: #404141;
    border-radius: 4px;
    color: #ffffff;
    font-size: 14px;
    position: absolute;
    padding: 5px 10px;
    right: 130%;
    bottom: 4px;
    white-space: nowrap;
    opacity: 0;
}

[data-title] {
    position: relative;
}

/* GLOWING EFFECT */
.glow {
    border: 1px solid #bbb;
    transition: box-shadow 0.2s ease;
}

.glow:hover {
    border: 1px solid #22ce98;
    box-shadow: 0 0 15px #22ce98;
}
.glown {
    transition: box-shadow 0.2s ease;
}

.glown:hover {
    box-shadow: 0 0 15px #22ce98;
}